import React, { useState } from 'react'
import { Alert, Checkbox, Collapse, Paper, Stack, Typography, TextField, InputAdornment, IconButton, Grid } from '@mui/material'
import { RemoveOutlined, AddOutlined } from '@mui/icons-material'
import { QuoteDetails } from './QuotesSharedComponents'

export default function WaybillResume({ sender, recipient, packet, shipment, setShipment, validatedWaybill, setValidatedWaybill, finalPrice, setFinalPrice, quantity, setQuantity }) {
  const userPermissions = JSON.parse(sessionStorage.getItem('permissions'))

  const [waybillResumeFinalPrice, setWaybillResumeFinalPrice] = useState(String(finalPrice))

  const waybill = {
    tipo_servicio_id: shipment.servicio_id,
    tipo_servicio_nombre: shipment.servicio_nombre,
    kg: packet.peso,
    alto: packet.alto,
    ancho: packet.ancho,
    largo: packet.largo,
    origenEmpresa: sender.empresa,
    origenPersona: `${sender.nombre} ${sender.apellidoPaterno} ${sender.apellidoMaterno ?? ''}`.trim(),
    origenCalle: sender.calle,
    origenNumExt: sender.numeroExterior,
    origenNumInt: sender.numeroInterior,
    origenColonia: sender.colonia,
    origenMunicipio: sender.municipio,
    origenEstado: sender.estado,
    origenPais: sender.pais,
    origenCP: sender.codigoPostal,
    origenTelefono1: sender.telefono1,
    origenTelefono2: sender.telefono2,
    origenRef: sender.referencia,
    destinoEmpresa: recipient.empresa,
    destinoPersona: `${recipient.nombre} ${recipient.apellidoPaterno} ${recipient.apellidoMaterno ?? ''}`.trim(),
    destinoCalle: recipient.calle,
    destinoNumExt: recipient.numeroExterior,
    destinoNumInt: recipient.numeroInterior,
    destinoColonia: recipient.colonia,
    destinoMunicipio: recipient.municipio,
    destinoEstado: recipient.estado,
    destinoPais: recipient.pais,
    destinoCP: recipient.codigoPostal,
    destinoTelefono1: recipient.telefono1,
    destinoTelefono2: recipient.telefono2,
    destinoRef: recipient.referencia,
    costoDeEnvio: Number(shipment.costo),
    valorDeMercancia: Number(shipment.mercancia),
    montoACobrar: shipment.quienPaga === 1 ? (Number(shipment.costo) + Number(shipment.mercancia)).toFixed(2) : 0,
    pago_en_destino: shipment.quienPaga,
    origenDestino: shipment.quienPaga === 1 ? 'Destino' : 'Origen',
    eta: shipment.eta,
    generadoPorUsuario: 1,
    esSobre: packet.esSobre
  }

  const catchFinalPrice = e => {
    const value = e.target.value.replace(/[^0-9.]/g, "").replace(/^(\d*\.?)|(\d*)\.?/g,"$1$2")

    setWaybillResumeFinalPrice(value)
    setFinalPrice(value !== "" ? Number(value) : 0)
  }

  return (
    <>
      <Stack spacing={2}>
        <Paper variant='outlined'>
          <QuoteDetails waybill={waybill} />
        </Paper>

        <Grid
          // direction="row"
          // alignItems="center"
          // justifyContent="space-between"
          container
          columnSpacing={1}
          rowSpacing={1}
        >
          {/* { 
            ['Admin', 'PDV'].includes(userPermissions['role']) && (
              <FormControl
                size='small'
                sx={{ width: 175 }}
              >
                <InputLabel id='shipment-selector-label'>¿Quién realiza el pago?</InputLabel>
                <Select
                  labelId='shipment-selector-label'
                  id='shipment-selector'
                  value={shipment.quienPaga}
                  label='¿Es un sobre tu paquete?'
                  onChange={event => setShipment({ ...shipment, quienPaga: event.target.value })}
                >
                  <MenuItem value={0}>Origen</MenuItem>
                  <MenuItem value={1}>Destino</MenuItem>
                </Select>
              </FormControl>
            )
          } */}

          <Grid
            item
            xs={12}
            sm={12}
            md={3.5}
            lg={3.5}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <TextField 
              label='Cantidad'
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <IconButton
                      onClick={() => setQuantity(quantity > 1 ? quantity - 1 : quantity)}
                    >
                      <RemoveOutlined sx={{ fontSize: '12px' }} />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => setQuantity(quantity < 999 ? quantity + 1 : quantity)}
                    >
                      <AddOutlined sx={{ fontSize: '12px' }} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              onChange={event => setQuantity(/^\d{0,3}$/.test(event.target.value) && Number(event.target.value) > 1 ? Number(event.target.value) : Number(quantity))}
              value={quantity}
              size='small'
            />
          </Grid>

          {
            !['SubCliente'].includes(userPermissions['role']) && <>
              <Grid
                item
                xs={12}
                sm={12}
                md={3.5}
                lg={3.5}
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <TextField
                  disabled={['Admin', 'PDV', 'Supervisor'].includes(userPermissions['role']) ? false : true}
                  label='Precio por envio'
                  placeholder='0.00'
                  value={waybillResumeFinalPrice}
                  onChange={catchFinalPrice}
                  sx={{width: 175}}
                  size="small"
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                display='flex'
                alignItems='center'
                justifyContent='center'
                gap='4px'
              >
                <Typography
                  fontSize='14px'
                  fontWeight='700'
                >
                  Subtotal: <span style={{ fontWeight: 400 }}>{parseFloat(finalPrice * quantity).toFixed(2)}</span>
                </Typography>
              </Grid>
            </>
          }
        </Grid>

        <Stack
          direction='row'
          alignItems='start'
        >
          <Checkbox
            checked={validatedWaybill}
            onChange={event => setValidatedWaybill(event.target.checked)}
          />
          <Typography>He leído y confirmo que los datos de la guía a generar son los correctos, conociendo qué, en caso de error, no será posible realizar modificaciones</Typography>
        </Stack>
      </Stack>

      <Collapse in={!validatedWaybill}>
        <Alert
          sx={{ mt: 2 }}
          severity='warning'
        >
          Antes de continuar, verifica que la información mostrada sea la correcta, ya que no se podrán realizar modificaciones una vez efectuado el pago de la guía
        </Alert>
      </Collapse>

      <Collapse in={shipment.quienPaga === 1}>
        <Alert
          sx={{ mt: 2 }}
          severity='info'
        >
          Debido a que el envío se cobrará en el destino, congelaremos el costo del envío. Una vez el destino haya pagado, reembolsaremos la cantidad en forma de saldo a favor
        </Alert>
      </Collapse>

      <Collapse in={shipment.quienPaga === 1 && Number(shipment.costo) + Number(shipment.mercancia) > 3000}>
        <Alert
          sx={{ mt: 2 }}
          severity='error'
        >
          En envíos donde el destino es quién paga, el monto a cobrar no puede superar los $3000
        </Alert>
      </Collapse>

      <Collapse in={finalPrice < shipment.costo}>
        <Alert
          sx={{ mt: 2 }}
          severity="error"
        >
          El precio final no debe ser menor al costo de envio
        </Alert>
      </Collapse>
    </>
  )
}
